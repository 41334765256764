<template>
	<div class="integral-order">
		<div class="el-content mb12">
			<div class="kd-pacel">检索</div>
			 <a-input-search style="width: 400px;" v-model:value="search.key" placeholder="订单编号" enter-button @search="getIntegralOrder(1,info.limit)" />
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'订单编号',dataIndex:'order_number'},
				{title:'商品信息',dataIndex:'goods',slots:{customRender:'goods'}},
				{title:'用户信息',dataIndex:'user',slots:{customRender:'user'}},
				{title:'订单信息',dataIndex:'order',slots:{customRender:'order'}},
				{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'收货信息',dataIndex:'delivery',slots:{customRender:'delivery'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #goods="{record}">
					<div style="display: flex;" v-if="record.detail">
						<img v-if="record.detail.images" :src="record.detail.images[0]" alt="" style="width: 50px;height: 50px;margin-right: 8px;">
						<div>
							<div>{{record.detail.name}}</div>
						</div>
					</div>
				</template>
				<template #user="{record}">
					<template v-if="record.user">
					   <div><span class="f12">昵称：</span>{{record.user.nickname}}</div>
					   <div><span class="f12">姓名：</span>{{record.user.true_name}}</div>
					   <div><span class="f12">user_id：</span>{{record.user.user_id}}</div>
					</template>
				</template>
				<template #order="{record}">
					<template v-if="record.detail">
					   <a-tag  v-if="record.detail.type==1">实物商品</a-tag>
					   <a-tag  v-if="record.detail.type ==2">虚拟商品</a-tag>
					   <a-tag  v-if="record.detail.type ==4">余额兑换</a-tag>
					   <div><span class="f12">支付积分：</span>{{record.total_integral}}</div>
					   <div><span class="f12">支付金额：</span>{{record.total_price}}元</div>
					</template>
				</template>
				<template #status="{record}">
					<div v-if="record.is_send==1">
						<a-tooltip >
							<template #title>
								<div>快递公司：{{record.express_company}}</div>
								<div>快递单号：{{record.express_no}}</div>
							</template>
							<span :style="{color:$status.getIntegralOrderStatus(record,true)}">
								{{$status.getIntegralOrderStatus(record)}}
							</span>
						</a-tooltip>
					</div>
					<div v-else>
						<span :style="{color:$status.getIntegralOrderStatus(record,true)}">{{$status.getIntegralOrderStatus(record)}}</span>
					</div>
					<div v-if="record.pay_time"><span class="f12">支付时间：</span>{{record.pay_time}}</div>
					<div v-if="record.send_time"><span class="f12">发货时间：</span>{{record.send_time}}</div>
					<div v-if="record.confirm_time"><span class="f12">收货时间：</span>{{record.confirm_time}}</div>
				</template>
				<template #delivery="{record}">
					<div v-if="record.exchange_type == 1">
					   <div><span class="f12">姓名：</span>{{record.name}}</div>
					   <div><span class="f12">电话：</span>{{record.mobile}}</div>
					   <div><span class="f12">地址：</span>{{record.address}}</div>
				   </div>
				</template>
				<template #action="{record}">
					<a-space>
						<a-button
							type="primary"
							@click="deliveryGoods(record.id)"
							v-has="{action:'wxapp_adhibition_integral_order_delivery',plat:0}"
							v-if="$status.getIntegralOrderBtnAuth(record,'send')">立即发货
						</a-button>
						<a-button 
						   type="primary" 
						   v-has="{action:'wxapp_adhibition_integral_order_confirm',plat:0}"
						   @click="confirmIntegralOrder(record.id)"
						   v-if="$status.getIntegralOrderBtnAuth(record,'confirm')">确认收货
						</a-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getIntegralOrder(info.page,e)}"
					@change="(e)=>{getIntegralOrder(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="deliveryForm.delivery" title="修改快递单号" @ok="expressSend" width="600px" @cancel="closeDeliveryOrder">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="快递平台：">
					<a-select v-model:value="deliveryForm.express_company" @change="getExpress">
						<a-select-option :value="index" v-for="(item,index) in expressData" :key="index">
							{{item.express_company}}
						</a-select-option>
					</a-select>
				  </a-form-item>
				  <a-form-item label="快递单号：">
					<a-input placeholder="快递单号" v-model:value="deliveryForm.express_no"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import integralModel from '@/api/addons/integral'
import tool from '@/util/tool.js'
export default{
	setup(){
		const state = reactive({
			search:{
				key:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			deliveryForm:{
				id:0,
				delivery:false,
				express_company:'',
				express_no:''
			},
			expressData:[],
		})
		state.expressData = tool.getExpressCompany();
		getIntegralOrder(1,state.info.limit)

		function getIntegralOrder(page,limit){
			integralModel.getIntOrder(page,limit,state.search,res=>state.info = {limit,...res})
		}

		function closeDeliveryOrder(flag){
			state.deliveryForm.delivery = false
			if( flag ) state.getIntegralOrder(state.info.page)
		}
		function deliveryGoods(id){
			state.deliveryForm.id = id
			state.deliveryForm.delivery = true
		}

		const expressSend = ()=>integralModel.intOrderSend(state.deliveryForm,()=>{
			getIntegralOrder(state.info.page,state.info.limit)
			state.deliveryForm = {
				id:0,
				delivery:false,
				express_company:'',
				express_no:''
			}
		})

		function getExpress(e){
			state.deliveryForm.express_company = state.expressData[e].express_company
			state.deliveryForm.express_code = state.expressData[e].express_code
		}

		const confirmIntegralOrder = id=>integralModel.confirmIntOrder(id,()=>{
			getIntegralOrder(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getIntegralOrder,
			closeDeliveryOrder,
			deliveryGoods,
			expressSend,
			getExpress,
			confirmIntegralOrder
		}
	}
}
</script>

<style>
</style>
